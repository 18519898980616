<!-- 招商 -->
<template>
  <div>
    <!-- 招商合作 -->
    <div class="eg_hwef">
      <img
        class="img_we"
        v-for="(item, index) in list"
        :key="index"
        :src="item"
        alt=""
      />
    </div>
    <van-tabbar v-model="active" active-color="#FF6600" inactive-color="#000">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="search">招商合作</van-tabbar-item>
      <van-tabbar-item icon="friends-o">客户电话</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import url from "../config";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      active: 0,
      list: [
        `${url.Url}zhangshangshouce_onemb-h5.jpg`,
        `${url.Url}zhanoshangshouce_twomb-h5.jpg`,
        `${url.Url}zhaoshangshouc_threemb-h5.jpg`,
        `${url.Url}zhaoshangshouce_fourmb-h5.jpg`,
        `${url.Url}zhaoshangshou_fivemb-h5.jpg`,
        `${url.Url}zhaoshangshou_sixmb-h5.jpg`,
        `${url.Url}zhaoshangshou_qimb-h5.jpg`,
        `${url.Url}zhaoshangshauc_eightmb-h5.jpg`,
        `${url.Url}zhaoshangshou_ninemb-h5.jpg`,
        `${url.Url}shangshangshouce_tenmb-h5.jpg`,
        `${url.Url}zhaoshangshou_elevenmb-h5.jpg`,
        `${url.Url}zhaoshangshou_welvemb-h5.jpg`,
        `${url.Url}shichangfenxiang_iconme_h5.png`,
        `${url.Url}shichangjiyu-icon_mb-h5.png`,
        `${url.Url}shichuangguimo-cind_mb-h5.png`,
        `${url.Url}shichangguimoshuliang-mb-h5.png`,
        `${url.Url}hezuofangan-ionndw-mb-h5.png`,
        `${url.Url}zhaoshangcahnhduuw_mb-h5.png`,
        `${url.Url}zhangshaizhnegcsd-mb-h5.png`,
        `${url.Url}hehuorenxuanyi-mb-iocn-h5.png`,
        `${url.Url}hehuorenyouyuyuge-mbh5-icon.png`,
        `${url.Url}jiaruchesiiyoushi-mb-h5.png`,
        `${url.Url}jianyuxinagcasd-huhywdwd-h5.png`,
        `${url.Url}jiehusad-mb-h5.png`,
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.img_we {
  width: 100%;
}
.eg_hwef {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>